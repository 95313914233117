"use client";

import {
	type SetBusinessContextMutationVariables,
	graphql,
} from "@evolve-storefront/types";
import { Button } from "@evolve-storefront/ui/base/Button";
import { Select } from "@evolve-storefront/ui/base/Select/Select";
import {
	Form,
	FormField,
} from "@evolve-storefront/ui/components/form-new/form";
import type { ResultOf } from "@graphql-typed-document-node/core";
import { useAuth } from "@labdigital/federated-token-react";
import { useClientGqlFetcher } from "@labdigital/graphql-fetcher";
import { useMutation } from "@tanstack/react-query";
import { ArrowLeftRight } from "lucide-react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { useStoreContext } from "~/hooks/useStoreContext";
import { throwErrors } from "~/lib/helpers/errors";
import { useRouter } from "~/lib/i18n/navigation";

type Props = {
	locale: string;
	data: ResultOf<typeof BusinessContextFragment> | undefined | null;
};

export const BusinessContextFragment = graphql(/* GraphQL */ `
	fragment BusinessUnits on Customer {
		businessUnits {
			id
			name
			stores {
				id
				name
			}
		}
	}
`);

const SetBusinessContextMutation = graphql(/* GraphQL */ `
	mutation SetBusinessContext(
		$businessUnitId: ID!
		$storeKey: String!
	) {
		setActiveBusinessContext(
			businessUnitId: $businessUnitId
			storeKey: $storeKey
		)
	}
`);

export const BusinessContextSelector = ({ data }: Props) => {
	const gqlClientFetch = useClientGqlFetcher();
	const { values } = useAuth();
	const { currency, storeKey, locale } = useStoreContext();
	const router = useRouter();
	const storeContext = { currency, storeKey, locale };

	const result =
		data?.businessUnits?.flatMap(
			(bu) =>
				bu.stores?.map((store) => [
					`${bu.id}:${store.id}`, // value
					`${bu.name} - ${store.name}`, // label
				]) ?? [],
		) ?? [];

	const { mutateAsync } = useMutation({
		mutationFn: (variables: SetBusinessContextMutationVariables) =>
			gqlClientFetch(SetBusinessContextMutation, {
				...variables,
			}).then(
				(res) => throwErrors(res.errors) ?? res.data?.setActiveBusinessContext,
			),
	});

	const currentBusinesContext = values?.values.businessContext;
	const currentValues: Record<string, string> = {};
	if (currentBusinesContext) {
		currentValues.businessContext = `${currentBusinesContext.businessUnit}:${currentBusinesContext.storeKey}`;
	}
	const form = useForm({ values: currentValues });

	// biome-ignore lint: lint/suspicious/noExplicitAny
	const handleSubmit = async (data: any) => {
		const value = data.businessContext as string;
		await mutateAsync(
			{
				businessUnitId: value.split(":")[0],
				storeKey: value.split(":")[1],
			},
			{
				onSuccess: () => {
					toast.success("success");
					setTimeout(() => {
						window.location.reload();
					}, 1000);
				},
			},
		);
	};

	return (
		<Form {...form}>
			<form
				onSubmit={form.handleSubmit(handleSubmit)}
				className="flex items-center gap-4"
			>
				<FormField name="businessContext">
					<Select {...form.register("businessContext")}>
						{result.map(([value, label]) => (
							<option key={value} value={value}>
								{label}
							</option>
						))}
					</Select>
				</FormField>
				<Button
					type="submit"
					variant="secondary"
					className="sm:justify-self-start"
				>
					<ArrowLeftRight className="size-6" />
				</Button>
			</form>
		</Form>
	);
};
