"use client";

import { cn } from "@evolve-storefront/ui/helpers/styles";
import { useAuth } from "@labdigital/federated-token-react";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslations } from "next-intl";
import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import { toast } from "sonner";

export const LogoutButton = forwardRef<
	ElementRef<"button">,
	Omit<ComponentPropsWithoutRef<"button">, "onClick">
>(({ children, className, ...props }, ref) => {
	const { logout } = useAuth();
	const queryClient = useQueryClient();
	const t = useTranslations("LogoutButton");

	/**
	 * Handles logging out
	 * Redirect logic after logging out is based on the specific guard component used that checks authentication state for a page.
	 */
	const handleLogout = async () => {
		// Clear the access token from the cookies
		toast.info(t("logging-out"));
		try {
			await logout();
			// Invalidate all queries as every user query (customer, cart, order, wishlist) is invalid after logout
			await queryClient.invalidateQueries();

			toast.success(t("success"));
		} catch (_) {
			toast.error(t("error"));
		}
	};

	return (
		<button
			ref={ref}
			className={cn(
				"text-left font-medium text-destructive text-sm",
				className,
			)}
			{...props}
			onClick={handleLogout}
		>
			{children}
		</button>
	);
});

LogoutButton.displayName = "LogoutButton";
