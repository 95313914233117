"use client";

import { cva } from "class-variance-authority";
import type { SelectHTMLAttributes } from "react";
import { forwardRef, useEffect, useRef } from "react";
import { cn } from "../../helpers/styles";

export type Props = {
	variant?: "default" | "text";
} & SelectHTMLAttributes<HTMLSelectElement>;

export const Select = forwardRef<HTMLSelectElement, Props>(function Select(
	{ className, variant = "default", onChange, ...props },
	ref,
) {
	const _ref = useRef<HTMLSelectElement | null>(null);

	useEffect(() => {
		if (!_ref.current || variant !== "text") return;
		setWidth(_ref.current);
	}, [_ref, variant]);

	return (
		<select
			className={cn(
				// reset
				"appearance-none",
				// text
				"text-sm md:text-base",
				// disabled
				"disabled:cursor-not-allowed disabled:opacity-50",
				selectVariants({ variant }),
				className,
			)}
			ref={(node) => {
				_ref.current = node;
				if (ref) {
					if (typeof ref === "function") {
						ref(node);
					} else {
						ref.current = node;
					}
				}
			}}
			aria-label={props["aria-label"]}
			{...props}
			onChange={(event) => {
				if (variant === "text") {
					setWidth(event.target);
				}

				onChange?.(event);
			}}
		/>
	);
});

export const selectVariants = cva("", {
	variants: {
		variant: {
			default: [
				// shape
				"w-full border border-gray-300 bg-white px-4 py-3 pr-14 shadow-sm",
				// font
				"font-medium",
				// caret
				"bg-no-repeat [background-image:--icon-select] [background-position:calc(100%-1rem)] [background-size:1.5rem]",
			],
			text: [
				// shape
				"relative inline-flex items-center gap-4 rounded bg-transparent pr-8 pl-2",
				// caret
				"bg-right bg-no-repeat [background-image:--icon-select]",
			],
		},
	},
	defaultVariants: {
		variant: "default",
	},
});

const setWidth = (element: HTMLSelectElement) => {
	const option = element.selectedOptions[0];
	const textWidth = option
		? getTextWidth(option.text, getCanvasFont(option))
		: 30;
	const paddingX =
		Number(
			window
				.getComputedStyle(element, null)
				.getPropertyValue("padding-left")
				.replace("px", ""),
		) +
		Number(
			window
				.getComputedStyle(element, null)
				.getPropertyValue("padding-right")
				.replace("px", ""),
		);
	element.style.width = `${paddingX + textWidth}px`;
};

let canvas: HTMLCanvasElement | undefined;

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param text The text to be rendered.
 * @param font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export const getTextWidth = (text: string, font: string) => {
	// re-use canvas object for better performance
	canvas ??= document.createElement("canvas");
	const context = canvas.getContext("2d");
	if (!context) return text.length * 6;
	context.font = font;
	const metrics = context.measureText(text);
	return metrics.width;
};

const getCssStyle = (element: HTMLElement, prop: string) =>
	window.getComputedStyle(element).getPropertyValue(prop);

export const getCanvasFont = (el = document.body) =>
	[
		getCssStyle(el, "font-weight") || "normal",
		getCssStyle(el, "font-size") || "16px",
		getCssStyle(el, "font-family") || "Times New Roman",
	].join(" ");
